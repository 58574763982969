<script setup lang="ts">
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import PopoverMenuItem from '@/components/atoms/Popover/PopoverMenuItem/Index.vue';
import PopoverMenuLink from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuLink.vue';
import { computed, defineProps, ref } from 'vue';
import { PrimaryNavLink } from '@/json_schema_types/sidebar_navigation';
import { useTestHelpers } from '@/composables/useTestHelpers';

interface Props {
  link: PrimaryNavLink;
  collapsed: boolean;
  highlighted?: boolean; // For active state usage
  focused?: boolean; // For sub tray focusing
}

const props = defineProps<Props>();
const { generateTestId } = useTestHelpers();
const faIconString = computed(() => {
  return props.highlighted ? `fa-solid ${props.link.icon}` : `fa-regular ${props.link.icon}`;
});

const isPopoverOpen = ref(false);
const popoverTopOffset = ref<string | null>(null);

const navigationButtonRef = ref<HTMLElement | null>(null);

const calculateTopOffset = (rectTop: number) => {
  if (!rectTop) return null;

  return `calc(${rectTop}px - 6rem)`;
};

const togglePopover = () => {
  if (navigationButtonRef.value && !isPopoverOpen.value) {
    const rect = navigationButtonRef.value.getBoundingClientRect();
    popoverTopOffset.value = calculateTopOffset(rect.top);
  }

  isPopoverOpen.value = !isPopoverOpen.value;
};
</script>

<template>
  <li>
    <button
      ref="navigationButtonRef"
      :class="[
        'navigation-button__button',
        { 'navigation-button__button--highlighted': props.highlighted },
        {
          'navigation-button__button--focused':
            (props.focused || isPopoverOpen) && !props.highlighted,
        },
      ]"
      :data-test-id="generateTestId('side-navigation-link', link.label)"
      @click="togglePopover"
    >
      <FontAwesomeIcon
        v-if="link.icon"
        :icon="faIconString"
        :data-test-id="generateTestId('navigation button icon', link.label)"
        size="lg"
        class="fa-fw"
      />
      <span class="navigation-button__label" :class="{ 'visually-hidden': collapsed }">{{
        link.label
      }}</span>
    </button>
    <Teleport defer to="#subnavs-popover">
      <PopoverMenu
        v-if="isPopoverOpen"
        class="subnav-popover"
        max-width="200px"
        max-height="300px"
        position="right"
        :style="{
          borderRadius: '1rem',
          left: collapsed
            ? 'calc(var(--side-navigation-width) + 1rem)'
            : 'calc(var(--side-navigation-width-expanded-dynamic) + 1rem)',
          ...(popoverTopOffset && { top: popoverTopOffset }), // TODO: Calculate according to height
        }"
        :toggle-element-selector="navigationButtonRef"
        :model-value="isPopoverOpen"
        @update:model-value="isPopoverOpen = $event"
      >
        <div class="subnav-popover__container tw-max-h-[300px] tw-overflow-y-auto">
          <template
            v-for="(navGroup, navGroupIndex) in link.children"
            :key="`subnav-popover-menu-group-${navGroupIndex}`"
          >
            <PopoverMenuItem padding="0.25rem 2.5rem">
              <strong class="subnav-popover__header h5">{{ navGroup.group_title }}</strong>
            </PopoverMenuItem>
            <template
              v-for="(item, itemIndex) in navGroup.links"
              :key="`subnav-popover-menu-item-${itemIndex}`"
            >
              <PopoverMenuItem :id="itemIndex" padding="0.5rem 1.5rem"
                ><PopoverMenuLink
                  :item="item"
                  :custom-styles="{ color: 'var(--navigation-text-color)' }"
                  is-nav
              /></PopoverMenuItem>
            </template>
          </template>
        </div>
      </PopoverMenu>
    </Teleport>
  </li>
</template>

<style scoped lang="scss">
.navigation-button__button {
  all: unset;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: var(--side-navigation-item-padding);
  gap: var(--side-navigation-icon-spacing);
  text-wrap: nowrap;
  border-radius: 4px;
  align-items: center;

  &:hover,
  &--focused {
    background-color: var(--navigation-hover-color);
  }

  &--highlighted,
  &--highlighted:hover {
    background-color: var(--navigation-highlight-color);
    font-weight: bold;
  }

  .navigation-button__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.subnav-popover {
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
}

.subnav-popover__container {
  &::-webkit-scrollbar {
    background-color: var(--navigation-color);
    width: 1.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--navigation-hover-color);
  }
}

.subnav-popover__header {
  color: var(--navigation-text-color);
}
</style>
